type GATagEvent = {
  action: string
  category: string
  label: string
  value: number
}

export const pageview = (url: URL) => {
  window.gtag("config", process.env.NEXT_PUBLIC_GA_TRACKING_ID as string, {
    page_path: url,
  })
}

export const gaTagEvent = ({ action, category, label, value }: GATagEvent) => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}
